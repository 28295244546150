<template>
  <div>
    <div class="py-3" style="max-width: 1100px">
      <submission-alert-message
        should-scroll-to-top
        ref="banner"
        v-model="showAlertCondition"
        :message="badExamFormMessage[$language]"
      ></submission-alert-message>
    </div>
    <v-card class="m-light-card">
      <v-tabs v-model="currentStep">
        <v-tab>
          {{ !$language ? "1. Question" : "১. প্রশ্ন" }}
        </v-tab>
        <v-tab>
          {{ !$language ? "2. Answers" : "২. উত্তর" }}
        </v-tab>
      </v-tabs>

      <v-tabs-items v-model="currentStep">
        <v-tab-item>
          <single-question-form-details
            ref="detailsForm"
            show-topic-input
            :question-details="question.questionDetails"
            :is-valid.sync="questionOk"
            :topics="topics.filter((t) => t.shared === false)"
            :readonly="readonly"
            data-qa="question-details-form"
            @input="$emit('input', { name: 'questionDetails', value: $event })"
            @updateqt="$emit('updateqt', $event)"
          ></single-question-form-details>
          <tab-navigation
            previous-disabled
            :language="$language"
            @next="nextStep"
            @previous="previousStep"
            @cancel="$emit('cancel')"
          ></tab-navigation>
        </v-tab-item>

        <v-tab-item>
          <single-question-form-answers-desc
            v-if="question.questionDetails.questionType === 'desc'"
            ref="answereForm"
            v-model="question.answerPreference"
            :is-valid.sync="answersOk"
            :readonly="readonly"
            @input="$emit('input', { name: 'answerPreference', value: $event })"
          ></single-question-form-answers-desc>
          <single-question-form-answers
            v-else
            ref="answereForm"
            :answers="question.answers"
            :question-type="question.questionDetails.questionType"
            :is-valid.sync="answersOk"
            :readonly="readonly"
            @input="$emit('input', { name: 'answers', value: $event })"
          ></single-question-form-answers>
          <tab-navigation
            :language="$language"
            next-disabled
            @next="nextStep"
            @previous="previousStep"
            @cancel="$emit('cancel')"
          >
            <template v-if="!readonly" v-slot:right>
              <m-rounded-button
                outlined
                color="red accent-3"
                data-qa="cancel-button"
                class="btn-outlined"
                @click="$emit('cancel')"
              >
                <v-icon left>cancel</v-icon>
                {{ !$language ? "Cancel" : "বাতিল করুন" }}
              </m-rounded-button>
              <m-rounded-button
                :loading="submitting"
                class="ml-2 btn-outlined"
                @click="save"
              >
                <m-icon left>save_alt</m-icon>
                {{ !$language ? "Submit" : "জমা দিন" }}
              </m-rounded-button>
            </template>
          </tab-navigation>
        </v-tab-item>
      </v-tabs-items>
    </v-card>
  </div>
</template>

<script>
import SingleQuestionFormAnswersDesc from "#ecf/question-bank/components/SingleQuestion/FormAnswersDesc";
import { TopicsProvider } from "#ecf/question-bank/mixins";
import TabNavigation from "~ecf/components/TabNavigation";
import SingleQuestionFormAnswers from "#ecf/question-bank/components/SingleQuestion/FormAnswers";
import SingleQuestionFormDetails from "#ecf/question-bank/components/SingleQuestion/FormDetails";
import SubmissionAlertMessage from "/global/components/SubmissionAlertMessage";

export default {
  name: "SingleQuestionForm",
  components: {
    SingleQuestionFormAnswersDesc,
    SingleQuestionFormDetails,
    SingleQuestionFormAnswers,
    TabNavigation,
    SubmissionAlertMessage,
  },
  mixins: [TopicsProvider],
  model: {
    prop: "question",
  },
  props: {
    courseId: {
      type: String,
      required: true,
    },
    question: {
      type: Object,
      required: true,
    },
    isValid: {
      type: Boolean,
      default: true,
    },
    submitting: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      currentStep: 0,
      questionOk: this.isValid,
      answersOk: this.isValid,
      showAlertCondition: false,
    };
  },
  computed: {
    badExamFormMessage() {
      return {
        false:
          "You must fill out all the mandatory fields" +
          (this.question?.questionDetails?.questionType == "mcq"
            ? ", For MCQ at least a single option must be correct"
            : ""),
        true:
          "সকল প্রয়োজনীয় তথ্য পূরণ করুন" +
          (this.question?.questionDetails?.questionType == "mcq"
            ? ", বহুনির্বাচনি প্রশ্নের জন্য অন্তত একটি সঠিক উত্তর প্রদান করুন"
            : ""),
      };
    },
    allowSaveCondition() {
      return this.questionOk && this.answersOk;
    },
  },
  watch: {
    questionOk(value) {
      this.$logger.log("watch:answersOk", value);
      this.$emit("update:is-valid", value && this.answersOk);
    },
    answersOk(value) {
      this.$logger.log("watch:answersOk", value);
      this.$emit("update:is-valid", value && this.questionOk);
    },
    allowSaveCondition(value) {
      if (value) this.showAlertCondition = false;
    },
  },
  methods: {
    nextStep() {
      if (this.currentStep !== 1) {
        this.currentStep++;
      }
    },
    previousStep() {
      if (this.currentStep !== 0) {
        this.currentStep--;
      }
    },
    async save() {
      if (this.allowSaveCondition) {
        this.$emit("submit");
      } else {
        await this.$refs.detailsForm.validate();
        await this.$refs.answereForm.validate();
        if (!this.showAlertCondition) this.showAlertCondition = true;
        else await this.$refs.banner.flash();
      }
    },
  },
};
</script>

<style></style>
