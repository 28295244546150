var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "py-3", staticStyle: { "max-width": "1100px" } },
        [
          _c("submission-alert-message", {
            ref: "banner",
            attrs: {
              "should-scroll-to-top": "",
              message: _vm.badExamFormMessage[_vm.$language],
            },
            model: {
              value: _vm.showAlertCondition,
              callback: function ($$v) {
                _vm.showAlertCondition = $$v
              },
              expression: "showAlertCondition",
            },
          }),
        ],
        1
      ),
      _c(
        "v-card",
        { staticClass: "m-light-card" },
        [
          _c(
            "v-tabs",
            {
              model: {
                value: _vm.currentStep,
                callback: function ($$v) {
                  _vm.currentStep = $$v
                },
                expression: "currentStep",
              },
            },
            [
              _c("v-tab", [
                _vm._v(
                  " " +
                    _vm._s(!_vm.$language ? "1. Question" : "১. প্রশ্ন") +
                    " "
                ),
              ]),
              _c("v-tab", [
                _vm._v(
                  " " + _vm._s(!_vm.$language ? "2. Answers" : "২. উত্তর") + " "
                ),
              ]),
            ],
            1
          ),
          _c(
            "v-tabs-items",
            {
              model: {
                value: _vm.currentStep,
                callback: function ($$v) {
                  _vm.currentStep = $$v
                },
                expression: "currentStep",
              },
            },
            [
              _c(
                "v-tab-item",
                [
                  _c("single-question-form-details", {
                    ref: "detailsForm",
                    attrs: {
                      "show-topic-input": "",
                      "question-details": _vm.question.questionDetails,
                      "is-valid": _vm.questionOk,
                      topics: _vm.topics.filter((t) => t.shared === false),
                      readonly: _vm.readonly,
                      "data-qa": "question-details-form",
                    },
                    on: {
                      "update:isValid": function ($event) {
                        _vm.questionOk = $event
                      },
                      "update:is-valid": function ($event) {
                        _vm.questionOk = $event
                      },
                      input: function ($event) {
                        return _vm.$emit("input", {
                          name: "questionDetails",
                          value: $event,
                        })
                      },
                      updateqt: function ($event) {
                        return _vm.$emit("updateqt", $event)
                      },
                    },
                  }),
                  _c("tab-navigation", {
                    attrs: { "previous-disabled": "", language: _vm.$language },
                    on: {
                      next: _vm.nextStep,
                      previous: _vm.previousStep,
                      cancel: function ($event) {
                        return _vm.$emit("cancel")
                      },
                    },
                  }),
                ],
                1
              ),
              _c(
                "v-tab-item",
                [
                  _vm.question.questionDetails.questionType === "desc"
                    ? _c("single-question-form-answers-desc", {
                        ref: "answereForm",
                        attrs: {
                          "is-valid": _vm.answersOk,
                          readonly: _vm.readonly,
                        },
                        on: {
                          "update:isValid": function ($event) {
                            _vm.answersOk = $event
                          },
                          "update:is-valid": function ($event) {
                            _vm.answersOk = $event
                          },
                          input: function ($event) {
                            return _vm.$emit("input", {
                              name: "answerPreference",
                              value: $event,
                            })
                          },
                        },
                        model: {
                          value: _vm.question.answerPreference,
                          callback: function ($$v) {
                            _vm.$set(_vm.question, "answerPreference", $$v)
                          },
                          expression: "question.answerPreference",
                        },
                      })
                    : _c("single-question-form-answers", {
                        ref: "answereForm",
                        attrs: {
                          answers: _vm.question.answers,
                          "question-type":
                            _vm.question.questionDetails.questionType,
                          "is-valid": _vm.answersOk,
                          readonly: _vm.readonly,
                        },
                        on: {
                          "update:isValid": function ($event) {
                            _vm.answersOk = $event
                          },
                          "update:is-valid": function ($event) {
                            _vm.answersOk = $event
                          },
                          input: function ($event) {
                            return _vm.$emit("input", {
                              name: "answers",
                              value: $event,
                            })
                          },
                        },
                      }),
                  _c("tab-navigation", {
                    attrs: { language: _vm.$language, "next-disabled": "" },
                    on: {
                      next: _vm.nextStep,
                      previous: _vm.previousStep,
                      cancel: function ($event) {
                        return _vm.$emit("cancel")
                      },
                    },
                    scopedSlots: _vm._u(
                      [
                        !_vm.readonly
                          ? {
                              key: "right",
                              fn: function () {
                                return [
                                  _c(
                                    "m-rounded-button",
                                    {
                                      staticClass: "btn-outlined",
                                      attrs: {
                                        outlined: "",
                                        color: "red accent-3",
                                        "data-qa": "cancel-button",
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.$emit("cancel")
                                        },
                                      },
                                    },
                                    [
                                      _c("v-icon", { attrs: { left: "" } }, [
                                        _vm._v("cancel"),
                                      ]),
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            !_vm.$language
                                              ? "Cancel"
                                              : "বাতিল করুন"
                                          ) +
                                          " "
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "m-rounded-button",
                                    {
                                      staticClass: "ml-2 btn-outlined",
                                      attrs: { loading: _vm.submitting },
                                      on: { click: _vm.save },
                                    },
                                    [
                                      _c("m-icon", { attrs: { left: "" } }, [
                                        _vm._v("save_alt"),
                                      ]),
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            !_vm.$language
                                              ? "Submit"
                                              : "জমা দিন"
                                          ) +
                                          " "
                                      ),
                                    ],
                                    1
                                  ),
                                ]
                              },
                              proxy: true,
                            }
                          : null,
                      ],
                      null,
                      true
                    ),
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }